import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Menu.css';  // Link your CSS file here

const Menu = () => {
  const { weekNumber } = useParams();  // Extract weekNumber from URL
  const [menuItems, setMenuItems] = useState([]);  // State for menu items

  useEffect(() => {
    fetch(`/api/menu/week/${weekNumber}`)  // Fetch the menu data from the API
      .then((response) => response.json())
      .then((data) => {
        setMenuItems(data);  // Set the fetched data
      })
      .catch((error) => console.error('Error fetching menu items:', error));
  }, [weekNumber]);

  return (
    <div>
      <h1>Menu for Week {weekNumber}</h1>
      <ul>
        {menuItems.map((item) => (
          <li key={item.id}>
            <h3>{item.name}</h3>
            <p>{item.description.replace(/\[.*?\]\s*/g, '')}</p>
            <p>Price: {item.overridePrice || 'N/A'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Menu;
