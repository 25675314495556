import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Menu from './components/Menu';
import MenuBar from './components/MenuBar';

function App() {
  function getWeekNumber() {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const pastDaysOfYear = (now - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  }

  

  const currentWeek = getWeekNumber();

  return (
    <div>
      {/* Place MenuBar outside app-container for full-width styling */}
      <MenuBar />

      {/* Main content inside app-container */}
      <div className="app-container">
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu/:weekNumber" element={<Menu />} />
            <Route path="/menu" element={<Navigate to={`/menu/${currentWeek}`} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
