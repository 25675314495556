import React, { useState } from 'react';
import './MenuBar.css'; // Import your CSS file
import logo from '../images//logo.svg'


const MenuBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    
    <header className="menu-bar">
      <div className="menu-content">
        <button className="menu-icon" aria-label="Toggle navigation menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger menu icon */}
        </button>
        <img src={logo} alt="VEAT Café Logo" className="menu-logo" />

        {/* Dropdown menu */}
        {menuOpen && (
          <div className="menu-items">
            <ul>
              <li><a href="#menu-week">Veckans meny</a></li>
              <li><a href="#what-we-offer">För den moderna arbetsplatsen</a></li>
              <li><a href="#our-fridge">Vår moderna kyl</a></li>
              <li><a href="#catering">Catering</a></li>
              <li><a href="#results">Omdömen</a></li>
              <li><a href="#contact-order">Nu kör vi igång</a></li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default MenuBar;
