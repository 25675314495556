import React, { useState } from 'react';
import ContactForm from './ContactForm'; // Ensure you import the ContactForm component
import './ContactBar.css'; // Ensure you have this file for styling
import './Home.css'

const ContactBar = () => {
  const [formVisible, setFormVisible] = useState(false);

  const toggleForm = () => {
    setFormVisible(!formVisible);
  };

  return (
    <div>
      {/* Only show the contact bar when the form is not visible */}
      {!formVisible && (
    
        <div className="contact-bar" onClick={toggleForm}>
          <p>Click to Contact Us</p>
        </div>
        
  
      )}

      {/* Contact Form that slides up */}
      {formVisible && (
        <div className='custom-row'>
          
        <div className={`contact-form-container ${formVisible ? 'active' : ''}`}>
          <div className="form-header">
          
            <p>Get in touch with us to schedule a meeting and find out how we can customize a menu offering for your office needs.</p>
            <div className="close-arrow" onClick={toggleForm}>   &#x25BC; {/* Down arrow symbol */}
          </div>
            <p>Two weeks free trial period<br />Money back guarantee<br />Two weeks lead time</p>
            
         
          </div>
         
          
        
          
          <ContactForm /> {/* Form fields */}
         </div>
        
          {/* Arrow for closing */}
          <div className="close-arrow" onClick={toggleForm}>
            &#x25BC; {/* Down arrow symbol */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBar;
