import React, { useEffect, useState } from 'react';
import ContactForm from './ContactForm';
import ContactBar from './ContactBar';
import './Home.css';
import './Contact.css';
import axios from 'axios';
import chefImage from '../images/veat-web-pics/1.jpg'; // Add your image paths correctly
import foodTableImage from '../images/veat-web-pics/2.jpg';
import dishImage1 from '../images/veat-web-pics/3.jpg';
import dishImage2 from '../images/veat-web-pics/4.jpg';
import logoImage from '../images/e.png';
import spImage from '../images/veat-web-pics/5.jpg';
import camera from '../images/camera.png';
import fridgeImage from '../images/veat-web-pics/6.jpg';
import onsiteFoodImage1 from '../images/veat-web-pics/7.jpg';
import onsiteFoodImage2 from '../images/veat-web-pics/8.jpg';
import onsiteFoodImage3 from '../images/veat-web-pics/9.jpg';
import onsiteFoodImage4 from '../images/veat-web-pics/10.jpg';
import onsiteFoodImage5 from '../images/veat-web-pics/11.jpg';
import signature from '../images/signature.png';
import hat from '../images/hat.png'

import logo2 from '../logos/Logos/286708_Volvo_Iron_Mark.jpg';
import logo3 from '../logos/Logos/WeWork-Icon-Logo.wine.svg';
import logo4 from '../logos/Logos/apoteket.png';
import logo5 from '../logos/Logos/fastp.png';
import logo6 from '../logos/Logos/h2gs.png';
import logo7 from '../logos/Logos/image (1).png';
import logo8 from '../logos/Logos/image (2).png';
import logo9 from '../logos/Logos/image (3).png';
import logo10 from '../logos/Logos/image (4).png';
import logo11 from '../logos/Logos/image (5).png';
import logo13 from '../logos/Logos/image (6).png';
import logo14 from '../logos/Logos/image (7).png';
import logo15 from '../logos/Logos/image (8).png';
import logo16 from '../logos/Logos/image (9).png';
import logo17 from '../logos/Logos/image (10).png';
import logo18 from '../logos/Logos/image (11).png';
import logo19 from '../logos/Logos/image.png';
import logo1 from '../logos/Logos/schibsted_logotype_black_rgb.png';
import logo12 from '../logos/Logos/sl-ab-storstockholm-lokaltrafik.svg';

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18];


const Home = () => {
    const [bowlItems, setBowlItems] = useState([]);
    const [snackItems, setSnackItems] = useState([]);
    const [drinkItems, setDrinkItems] = useState([]);
    const [soupItems, setSoupItems] = useState([]);
    const [breakfastItems, setBreakfastItems] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [showFullMenu, setShowFullMenu] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0); // Store scroll position

    const getWeekNumber = () => {
        const currentDate = new Date();
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const pastDaysOfYear = (currentDate - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    };

    

    const toggleForm = () => {
        setFormVisible(!formVisible); // Toggle the visibility state
      };

    const fetchMenuItems = async (category) => {
        try {
            const currentWeekNumber = getWeekNumber();
            // const baseUrl = process.env.NODE_ENV === 'production'
            //     ? process.env.REACT_APP_API_URL_PROD
            //     : process.env.REACT_APP_API_URL_DEV;
            const baseUrl = 'https://web.veat.se';
    
            const response = await axios.get(`${baseUrl}/api/menu/week/${currentWeekNumber}?category=${category}`);
            console.log(`Fetched items for category ${category}:`, response.data); // Log fetched data
    
            const itemsWithImages = response.data.filter(item => item.imageUrl);
            return itemsWithImages;
        } catch (error) {
            console.error(`Error fetching menu items for category ${category}:`, error.message);
            return [];
        }
    };
    

    const getInitialDisplayedItems = (bowls, snacks, drinks, soups, breakfast) => {
        console.log("Input Bowls:", bowls);
        console.log("Input Snacks:", snacks);
        console.log("Input Drinks:", drinks);
        console.log("Input Soups:", soups);
        console.log("Input Breakfast:", breakfast);
    
        const maxItems = 9; // Maximum items to display (3 rows of 3 items each)
        const display = Array(maxItems).fill(null); // Initialize array with `null` placeholders
    
        // Step 1: Fill the first 6 positions with non-snack and non-breakfast items
        const nonSnackAndBreakfastItems = [...bowls, ...drinks, ...soups];
        console.log("Non-Snack & Non-Breakfast Items (before filling):", nonSnackAndBreakfastItems);
    
        for (let i = 0; i < 6 && nonSnackAndBreakfastItems.length > 0; i++) {
            display[i] = nonSnackAndBreakfastItems.shift(); // Fill positions 1–6 (indexes 0–5)
        }
        console.log("Display after filling non-snack and non-breakfast items:", display);
    
        // Step 2: Add snacks and breakfast explicitly to positions 7, 8, and 9 (indexes 6–8)
        const combinedLastRowItems = [...snacks, ...breakfast];
        console.log("Combined Last Row Items (snacks + breakfast):", combinedLastRowItems);
    
        for (let i = 6; i < 9 && combinedLastRowItems.length > 0; i++) {
            display[i] = combinedLastRowItems.shift();
        }
        console.log("Display after adding snacks and breakfast to the last row:", display);
    
        // Step 3: Backfill any remaining `null` slots with leftover non-snack and non-breakfast items
        for (let i = 0; i < maxItems; i++) {
            if (!display[i] && nonSnackAndBreakfastItems.length > 0) {
                display[i] = nonSnackAndBreakfastItems.shift();
            }
        }
        console.log("Display after backfilling:", display);
    
        // Return the final array
        return display.filter(item => item !== null); // Remove any remaining `null` values
    };
    
    
    
    

    useEffect(() => {
        const fetchAllItems = async () => {
            const bowls = await fetchMenuItems('BOWL');
            const snacks = await fetchMenuItems('SNACK');
            const drinks = await fetchMenuItems('DRINK');
            const soups = await fetchMenuItems('SOUP');
            const breakfast = await fetchMenuItems('BREAKFAST');
    
            // Generate the initial display with proper snack placement
            const initialDisplay = getInitialDisplayedItems(bowls, snacks, drinks, soups, breakfast);
    
            setDisplayedItems(initialDisplay);
            setBowlItems(bowls);
            setSnackItems(snacks);
            setDrinkItems(drinks);
            setSoupItems(soups);
            setBreakfastItems(breakfast);
        };
    
        fetchAllItems();
    }, []);
    

    const handleViewFullMenu = () => {
        setShowFullMenu(true);
    
        // Display all items in any order for the full menu view
        const fullMenu = [...bowlItems, ...snackItems, ...drinkItems, ...soupItems, ...breakfastItems];
        setDisplayedItems(fullMenu);
    };
    
    
    const handleShowLessItems = () => {
        setShowFullMenu(false);
    
        // Reset to initial state with proper snack placement
        const initialDisplay = getInitialDisplayedItems(bowlItems, snackItems, drinkItems, soupItems, breakfastItems);
        setDisplayedItems(initialDisplay);
      };

    return (
        <div className="container">
          
                <h2 className="menu-week" id="menu-week">Meny vecka {" "}
                        {getWeekNumber()}</h2>
        
            <div className="main-content">
                

                {/* Display Items in Grid */}
                <div className="food-items">
                    {displayedItems.map((item, index) => (
                        <div key={index} className="food-item-container">
                            <img src={item.imageUrl} alt={item.name} className="food-item" />
                            <div className="food-item-details">
                                <h3>{item.name}</h3>
                                <p>{item.description.replace(/\[.*?\]\s*/g, '')}</p>
            
                            </div>
                        </div>
                    ))}
                </div>

                <div className="view-menu-wrapper">
        <div className="view-menu-button-container">
        <hr class="line-above-button"></hr>
          {!showFullMenu ? (

            <button onClick={handleViewFullMenu} className="view-menu-button">
              Se hela menyn +
            </button>
          ) : (
            <button onClick={handleShowLessItems} className="view-menu-button">
              Se mindre -
            </button>
          )}
          
        </div>
      </div>
                {/* New Sections */}
                <div className="additional-sections">
                <h2 id="what-we-offer">För den moderna
                 arbetsplatsen</h2>
                    <div className="custom-row two-items">
                        <div className="custom-card">
                            
                        
                        <div className="custom-card custom-signature">
                            
                            <p className='signature-text chef'>Veat Café har ett hälsosamt erbjudande av färska rätter och snacks, tillagat och levererat dagligen från vårt kök i Liljeholmen. Menyn består av enkla men smakrika rätter på noga utvalda råvaror som varierar efter årstid. Vår ambition är att det ska vara enkelt för alla att äta hälsosamt och hållbart.
                    
                         </p>  <p className='signature-text chef-2'>Med en Veat Café-kyl får arbetsplatsen enkel tillgång till färska hälsosamma rätter och snacks, dygnet runt. I erbjudandet ingår även catering av frukost, fika och luncher.
                            </p> </div>
                        </div>
                        <div className='custom-card chef'>
                        <img src={chefImage} alt="Chef" className="custom-image" />
                        </div>
                    </div>

                    <div className="custom-row three-items">
                        <div className='custom-card'>
                        <img src={foodTableImage} alt="Food table" className="custom-image" />
                        </div>
                        <div className='custom-card'>
                        <img src={dishImage1} alt="Dish 1" className="custom-image" />
                        </div>
                        <div className='custom-card'>
                        <img src={dishImage2} alt="Dish 2" className="custom-image" />
                        </div>
                    </div>

                    <div className="custom-row two-items">
                    <div className="custom-card logos-grid">
                    {logos.map((logo, index) => {
    // Skip rendering for indexes 3, 4, and 5
    if (index === 3 || index === 4 || index === 5) {
        return null;
    }

    // Replace the source of logo-9 with logo-18
    const logoSource = index === 9 ? logos[3] : logo;

    return (
        <div className={`logo-container logo-${index}`} key={index}>
            <img src={logoSource} alt={`Logo ${index + 1}`} className={`logo-image logo-image-${index}`} />
        </div>
    );
})}


</div>


                        <div className="custom-card signature">
                        <div className="custom-card custom-signature letter">
                            
                            <p className='signature-text'>“Vår meny innehåller rätter för alla tillfällen och energibehov. Gemensamt är en toppkvalitet på råvaror och smak, men också ett lågt klimatavtryck och näringsrikt innehåll. Varje månad introduceras nyheter baserat på säsong.”</p>
<div className='signature'>
    <div className='signature-pic'>
<img src={signature} alt="Signature" className="custom-image signature" /></div>
<div className='fabian'>
<p >Fabian Axelson</p>
<p >Kulinarisk Ledare</p>
</div></div>
                        </div>
                        </div>
                    </div>
                    <div className="custom-row three-items">
                      
                        <div className="custom-card signature2"> 
                        <div className="custom-card custom-signature first">
                            
                            <p className='signature-text li'><p className='signature-text title'>Välj Veat Café för hälsan och klimatet</p>  
                            <p className='signature-text title'>
                            <ul className='signature'><li>Med oss får ni hälsosam energi under hela dagen</li> 
                            <li>Alltid svenskt ekologiskt och KRAV-märkt kött från noggrant utvalda bondgårdar</li>
                          
                             </ul></p>
                            </p>
                            </div>
                        </div>
<div className="custom-card">
<div className="custom-card custom-signature second">
                            
                            <p className='signature-text li'> <ul><li>Menyn är huvudsakligen vegetarisk med ett klimatavtryck som är ca 70% lägre än en genomsnittlig svensk lunch</li>
                            <li>Färska kocklagade rätter tillagade från grunden, inga onaturliga ingredienser eller lång hållbarhetstid</li>
          
                            </ul>
                            
    </p></div>
               </div>    <div className='custom-card sp'> 
                          
                        <img src={spImage} alt="sp" className="custom-image" />
                        </div> 
                    </div>

                    {/* New Section: Onsite Fridge */}
                    <h2 id="our-fridge">Vår kyl</h2>
                    <div className="custom-row two-items">
                        <div className='custom-card'>
                        <img src={fridgeImage} alt="Fridge" className="custom-image" />
                        </div>
                        <div className="custom-card signature4">
                            
                        <div className="custom-card custom-signature">
                            
                            <p className='signature-text fridge'>Med vår kyl har det aldrig varit enklare att äta hälsosamt och hållbart, vi löser allt så att ni kan fokusera på annat. Kylen är 70x70x190cm.  
                                </p>      <p className='signature-text li'> <ul><li className='numbers'><p>Maten tillagas varje dag i vårt kök i Liljeholmen</p></li>
                            <li className='numbers'><p>Våra egna förare fyller varje morgon på kylen på med färska hälsosamma rätter och snacks</p></li>
                            <li className='numbers'><p>Betalning sker vi vår webbapp (Swish eller Apple / Google pay)</p></li>
                            <li className='numbers'><p>Osålda rätter doneras till Matmissionen</p></li>
                      </ul>
                            
    </p>
                        </div>
                        </div>
                    </div>

                    <div className="custom-row three-items">
                        <div className='custom-card'>
                        <img src={onsiteFoodImage1} alt="Onsite Food 1" className="custom-image" />
                        </div>
                        <div className='custom-card'>
                        <img src={onsiteFoodImage2} alt="Onsite Food 2" className="custom-image" />
                        </div>
                        <div className='custom-card'>
                        <img src={onsiteFoodImage3} alt="Onsite Food 3" className="custom-image" />
                        </div>
                       
                    </div>
                    <h2 id="catering">Catering</h2>
                    <div className="custom-row three-items">
                        <div className="custom-card">
                      
                            
                            <div className="custom-card custom-signature klimat">
                                <div className='signature-text catering'>
                                <p className='klimat' >Veat Café: den klimatsmarta one-stop-shop-lösning för arbetsplatsens samtliga matbehov.</p> 

                       <p className='plats'>Vi är på plats varje dag för att fylla på kylen och tar gärna med er cateringbeställning till 
                        frukosten, fikat eller luncheventet. 
                       </p> </div>
                                </div>
                        </div>
                       
                        <div className='custom-card'>
                        <img src={onsiteFoodImage4} alt="plate" className="custom-image" />
                        </div>
                        <div className='custom-card'>
                        <img src={onsiteFoodImage5} alt="plates" className="custom-image" />
                        </div>
                        
                    </div>
                    <div className='omdomen'>
                    <h2 id="results">Omdömen</h2>
                    <div className="custom-row two-items ">
                   
    <div className="custom-card testimonials">
    <div class="testimonial-row">
    <div class="testimonial-card left-align">
      <p>“Häslosamt och gott</p>
      <span>Torkel på Comhem</span>
    </div>
  </div>

  <div class="testimonial-row">
    <div class="testimonial-card">
      <p>“Fantastisk kundtjänst”</p>
      <span>Magda på H&M</span>
    </div>
    <div class="testimonial-card">
      <p>“Kunde inte vara nöjdare”</p>
      <span>Magda på H&M</span>
    </div>
  </div>

  <div class="testimonial-row">
    <div class="testimonial-card">
      <p>“Alla borde ha Veat”</p>
      <span>Magda på H&M</span>
    </div>
    <div class="testimonial-card">
      <p>“Snabb och enkel installation”</p>
      <span>Magda på H&M</span>
    </div>
  </div>

  <div class="testimonial-row">
    <div className='testimonial-card'><p>“Otroligt gott!”</p>
    <span>Magda på H&M</span></div>
    <div class="testimonial-card">
      <p>“Tillgängligt och prisvärt!”</p>
      <span>Magda på H&M</span>
    </div>
  </div>
    </div>
    <div className='custom-card ratings'>
    <div className="ratings">
        <div className="rating-stars">
            <div className="stars">★★★★★  <p>Smak och fräschhet</p></div>
            
        </div>
        <div className="rating-stars">
            <div className="stars">★★★★★  <p>Häsosamt</p></div>
            
        </div>
        <div className="rating-stars">
            <div className="stars">★★★★★  <p>Variation</p></div>
        </div>

        <div className="bars">
            <div className="bar">
                <div className="bar-fill" style={{width: '50%'}}></div>
                <span>162 ton CO2e sparat</span>
            </div>
            <div className="bar">
                <div className="bar-fill" style={{width: '25%'}}></div>
                <span>135,000 serverade rätter</span>
            </div>
            <div className="bar">
                <div className="bar-fill" style={{width: '25%'}}></div>
                <span>70% Lägre klimatavtryck*</span>
            </div>
            <div className="bar">
                <div className="bar-fill" style={{width: '3%'}}></div>
                <span>3% Matsvinn som doneras</span>
            </div>
        </div>
        <p className='bars'>* i jämförelse med en
        genomsnittlig svensk lunch</p>
    </div>
    </div>
   


                    </div>
                    </div>
                    <h2 id="contact-order">Nu kör vi igång</h2>
                   
<div className='call'>

    {/* Left Section with text and image */}
    <div className="custom-card custom-signature inTouch">
        <p className='first'>
        Kontakta oss för att boka in en provsmakning så utforskar vi ett 
        skräddarsytt erbjudande utifrån din arbetsplats matbehov. 
        </p>
        <p className='second'>
        <h3>Prova gratis i
        trettio dagar</h3>
        <h3>Ingen kostnad
        för installation</h3>
        <h3>Kom igång på två veckor</h3>
        </p>
        <img src={hat} alt="Hat" className="hat-image" />
    </div>

    {/* Right Section with contact form */}
    <div className="custom-card custom-signature started">
        <p>
            Kontakta oss på <a href="mailto:hello@veat.se">hello@veat.se</a>, ring Andreas på +46 72 296 11 70, eller fyll i formuläret nedan.
        </p>
        <ContactForm />
    </div>
</div>

    
        <div className="custom-row social-media">
    <div className="custom-card social-card">
        
        <div className="contact-details">
            <p>hello@veat.se</p>
            <p>Östermalmsgatan 26a</p>
            <p>114 26, Stockholm</p>
        </div>
        <div className="links">
          
        </div>
        
    </div>
    
</div>
 
                </div>
                
            </div>
             <div className='background'>
               
                <div className='background'>
            {/*<ContactBar />*/}
            </div>
            </div>
</div>


        
    );
    
}

export default Home;